/* react-dates */
.DateInput_input {
    font-size: 15.5px;
    font-weight: 500;
    color: #76797c;
    background-color: #FFFFFF;
    padding: 4px 11px 2px !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: #efbe39;
  border: 1px solid #efbe39;
}

.DayPickerKeyboardShortcuts_show__bottomRight,
.DayPickerKeyboardShortcuts_show__bottomRight:hover {
  display: none;
}